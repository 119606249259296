<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.807" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_openingtimes" transform="translate(-1226 -2355)">
    <rect :fill="background" data-name="Rectangle 186" height="183" id="Rectangle_186" transform="translate(1226 2355)" width="183"/>
    <path d="M51.941.563a51.378,51.378,0,1,0,51.378,51.378A51.37,51.37,0,0,0,51.941.563ZM63.77,73.093,45.5,59.813a2.5,2.5,0,0,1-1.015-2.01V22.937a2.493,2.493,0,0,1,2.486-2.486h9.944A2.493,2.493,0,0,1,59.4,22.937V51.464l13.155,9.571a2.485,2.485,0,0,1,.539,3.48l-5.842,8.038A2.5,2.5,0,0,1,63.77,73.093Z" data-name="Icon awesome-clock" fill="url(#linear-gradient)" id="Icon_awesome-clock" transform="translate(1265.559 2394.559)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>